import React, { useState, useEffect } from 'react';
import { VenueContext } from './venue.context';
import { FindVenueOutput } from 'services/venue/venue.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useAPIVenue } from 'services/venue/api-venue.service';
import { Role } from 'auth/services/auth/auth.service';

interface VenueProviderProps {
    children: React.ReactNode;
}

const VenueProvider: React.FC<VenueProviderProps> = (props) => {
    const [session] = useLocalSession();
    const venuesService = useAPIVenue();
    const [venue, setVenue] = useState<FindVenueOutput>();
    const [venues, setVenues] = useState<FindVenueOutput[]>();

    const fetchVenues = () => {
        venuesService.getVenuesByOrg(session.organizationId).then(function (venues) {
            setVenues(venues);
            setVenue((prev) => {
                if (!prev) return undefined;
                const actualVenue = venues.find((venue) => venue.id === prev.id);
                return actualVenue;
            });
        });
    };

    useEffect(() => {
        let cancel = false;
        if (venues) return;
        venuesService.getVenuesByOrg().then((venues) => {
            if (cancel) return;
            if (session.user && session.user?.role != Role.ROOT_USER) {
                const venue = venues.find(({ id }) => id === session.user?.venue?.id);
                if (venue) setVenue(venue);
            } else {
                setVenues(venues);
                setVenue(venues[0]);
            }
        });
        return () => {
            cancel = true;
        };
    }, []);

    useEffect(() => {
        if (venue && venues) {
            // const averageRating =
            //     session.googleMetrics?.googleComparisonDataSource?.find(
            //         (googleMetric) => googleMetric.venue === venue?.name,
            //     )?.averageRating || 0;
            // const totalReviewCount =
            //     session.googleMetrics?.googleComparisonDataSource?.find(
            //         (googleMetric) => googleMetric.venue === venue?.name,
            //     )?.totalReviewCount || 0;
            // const foundVenue =
            //     session.googleMetrics?.googleComparisonDataSource?.find(
            //         (googleMetric) => googleMetric.venue === venue?.name,
            //     )?.venue || '';
            // setSession({
            //     ...session,
            //     googleMetrics: {
            //         state: googleMetricsQuery.isLoading
            //             ? 'loading'
            //             : googleMetricsQuery.isFetched
            //             ? 'fetched'
            //             : 'no-integrations',
            //         ...session.googleMetrics,
            //         googleKpis: { averageRating, totalReviewCount, venue: foundVenue },
            //     },
            // });
            setVenues((prevValues) => {
                if (prevValues) {
                    const foundVenue = prevValues.find((venueInVenues) => venueInVenues.id == venue.id);
                    if (foundVenue) {
                        const indexOfFoundVenue = prevValues.indexOf(foundVenue);
                        prevValues[indexOfFoundVenue] = venue;
                        return [...prevValues];
                    }
                }
            });
        }
    }, [venue]);

    return (
        <VenueContext.Provider value={{ venue, venues, setVenue, fetchVenues }}>{props.children}</VenueContext.Provider>
    );
};

export default VenueProvider;
